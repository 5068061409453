@import "../../../styles/variables.module";
.services {
  .sectionTitle {
    margin-bottom: 55px;
    @include large_device {
      margin-bottom: 20px;
    }
  }
  .serviceImg {
    -webkit-box-shadow: 3px 6px 20px rgb(61 90 112 / 15%);
    box-shadow: 3px 6px 20px rgb(61 90 112 / 15%);
    border: 10px solid $white-color;
  }
  .nav {
    display: block;
    @include medium_device {
      margin-left: 10px;
      margin-bottom: 15px;
    }
    .navItem {
      &:last-child {
        .servicesTab {
          margin-bottom: 0;
        }
      }
      .servicesTab {
        border-radius: 10px;
        padding: 0;
        margin-bottom: 24px;
        background-color: $white-color;
        width: 80%;
        cursor: pointer;
        border: 1px solid transparent;
        @include large_device {
          width: 100%;
          margin-bottom: 15px;
        }
        &.active {
          border-color: $secondary-color;
          .index {
            background-color: $secondary-color;
            color: $theme-color;
          }
          .title {
            margin-bottom: 8px;
          }
          .desc {
            display: block;
            font-size: 16px;
            color: $desc-font;
            @include large_device {
             font-size: 14px;
             line-height: 24px;
            }
          }
        }
        .serviceBox {
          position: relative;
          padding: 16px 16px 16px 32px;
          @include large_device {
            padding: 12px 12px 12px 32px;
          }
        }
        .index {
          width: 40px;
          height: 40px;
          position: absolute;
          background: $theme-color;
          border: 4px solid $body-color;
          color: $white-color;
          border-radius: 50px;
          text-align: center;
          line-height: 32px;
          font-weight: bold;
          font-size: 18px;
          left: -20px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
        .title {
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
          margin-bottom: 0;
          color: $theme-custom-color;
          @include medium_device {
            font-size: 16px;
          }
        }
        .desc {
          display: none;
          width: 100%;
        }
      }
    }
  }
  @include medium_device {
      .tabContent {
        text-align: center;
      }
  }
}
